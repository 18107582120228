:root {
    --btn-section-background: #385DBD
}

.count-btn-container {
    background-color: #2BBEDE;
    font-weight: semibold;
    color: white;
    font-size: 1.3rem;

    height: 3rem;
    width: 12rem;

    border-radius: 10px;
}

.btn-fav-logo {
    font-size: 1.6rem;

    color: #30D7FC;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    background-color: var(--btn-section-background);

    display: flex;
    align-items: center;

    border-radius: 0px 10px 10px 0;
    transition: all .28s ease-in-out;
}

.btn-fav-logo:hover {
    cursor: pointer;
    background-color: #1462D7;
}

.btn-fav-logo-active-bg{
    background-color: #3902D6;
}

.btn-fav-logo-active {
    color: #e30548;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.btn-episode-count {
    font-size: 1.4rem;

    padding-left: .8rem;
    padding-right: .8rem;
    color: #03EB60;
    background-color: var(--btn-section-background);

    display: flex;
    align-items: center;

    border-radius: 0px 10px 10px 0;
}